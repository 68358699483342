import { authorize } from '@smartapp/auth-lib'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import * as firebaseui from 'firebaseui'
import i18n from './i18n'
import { Settings } from 'luxon'

const locale = window.navigator.language.split('-')[0]
if (i18n.availableLocales.includes(locale)) i18n.locale = locale
Settings.defaultLocale = i18n.locale

//Configuration firebase :
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  application: process.env.VUE_APP_APP_NAME,
  country: process.env.VUE_APP_COUNTRY
}

initializeApp(config)
const ui = new firebaseui.auth.AuthUI(getAuth())
const auth = getAuth()

onAuthStateChanged(auth, async firebaseUser => {
  if (firebaseUser) {
    authorize(config, firebaseUser, process.env.VUE_APP_BACKEND_SMART)
      .then(({ user, roles }) => {
        import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
          createApp.createApp({firebaseUser, user, roles})
        })
      })
      .catch(reason => {
        console.warn(reason)
        if (reason.status === 403) {
          import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
            createApp.createForbiddenApp({ ...reason, firebaseUser })
          })
        } else {
          window.location.href = '/error.html'
        }
      })
  } else {
    ui.start('#firebase-ui', {
      signInFlow: 'popup',
      signInSuccessUrl: '/',
      signInOptions: [
        {
          provider: 'oidc.pass',
          buttonColor: '#2A2A2D',
          iconUrl: 'null',
          fullLabel: i18n.t('loginTo'),
          providerName: 'Pilot Stocks'
        }
      ]
    })
  }
})